<template>
  <div class="home" id="home">
    <!-- <div v-if="maskBox" class="mask-box"></div> -->
    <div v-if="loadingShow" class="loading-box"></div>
    <div v-if="boxShow">
      <Header :languageData="languageData" :parseVal="parseVal" :isSelect="true" />
      <div class="best-games-container" id="bestGames">
        <img class="img" src="@/assets/img/mobile/top-bg1.png" >
        <img class="img2" src="@/assets/img/mobile/top-bg2.png" >
        <img class="img3" src="@/assets/img/mobile/top-bg3.png" >
        <img class="img4" src="@/assets/img/mobile/top-bg4.png" >
        <div class="best-games-title">gfg best games</div>
        <div class="best-games-footer">
          <div class="best-games-btn">
            <img :src="require('@/assets/img/mobile/arrow.png')" class="image"/>
          </div>
        </div>
      </div>

      <div class="video-container" id="videoContainer">
        <div class="video-title" :class="{ animate__bounceIn: animate1 }">{{filterFn('filmText', 'title')}}</div>
        <div class="video-footer">
          <div v-if="!videoShow" class="video-bg" @click="videoBg"> 
            <img style="width: 100px;height: 100px;" :src="require('@/assets/img/mobile/pause.png')" class=""/>
          </div>
          <!--  -->
          <video id="bg_video" ref="videoPlayer" controls 
            x5-video-player-fullscreen="true"
            x5-playsinline
            playsinline
            webkit-playsinline
            preload="auto" 
            :poster="'https://gfgadmin.dr5kr.live/td/api/photo/' + viedoFn('film', 'phoneSrc')">
            <source v-if="videoShow" :src="'https://gfgadmin.dr5kr.live/td/' + viedoFn('film', 'phoneViedo')" type="video/mp4" />
          </video>
          <!-- <button id="playButton" src="preview.png" class="play-button" v-if="showPlayButton" @click="togglePlay"></button> -->
          <!-- <img id="prev
            iew" src="" alt="Video Preview"> -->
        </div>
      </div>

      <div class="popular-games-container" id="popularGames">
        <div class="popular-games-title" :class="{ animate__bounceIn: animate2 }">{{ filterFn('popularGameText', 'title') }}</div>
        <div class="popular-games-explain" :class="{ animate__bounceIn: animate2 }" v-html="filterFn('popularGameText', 'text')" ></div>
        <div class="popular-games-swiper">
          <img :src="require('@/assets/img/mobile/star_light.png')" class="breathe-div swiper-star1"/>
          <img :src="require('@/assets/img/mobile/star_light.png')" class="breathe-div swiper-star2"/>
          <img :src="require('@/assets/img/mobile/star_light.png')" class="breathe-div swiper-star5"/>
          <img :src="require('@/assets/img/mobile/star_light.png')" class="breathe-div swiper-star6"/>
          <img :src="require('@/assets/img/mobile/star_light.png')" class="breathe-div swiper-star4"/>
          <div @mouseleave="leave" @mouseenter="enter">
            <swiper ref="mySwiper" :options="swiperOption">
              <swiper-slide v-for="(item, index) in hotGameList" class="swiper-slide"
                :key="index">
                <!-- <game-item 
                  form="swiper"
                  :game="hotGameList[index]" >
                </game-item> -->
                <game-item :parseVal="parseVal" form="swiper" class="animate__animated" :class="{ animate__fadeInRight: animate1 }" :game="item" ></game-item>
              </swiper-slide>
            </swiper>
          </div>
          
          <div @mouseenter="enter" @mouseleave="leave">
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
        </div>
        <div class="popular-games-list">
          <img class="image-items animate__animated ig1" @click="toGameDetails(301)" :class="{ animate__fadeInLeft: animate3 }" :src="require('@/assets/img/mobile/game-icon-01.png')" />
          <img class="image-items animate__animated ig2" @click="toGameDetails(313)" :class="{ animate__fadeInUp: animate3 }" :src="require('@/assets/img/mobile/game-icon-02.png')" />
          <img class="image-items animate__animated ig3" @click="toGameDetails(310)" :class="{ animate__fadeInDown: animate3 }" :src="require('@/assets/img/mobile/game-icon-03.png')" />
        </div>
      </div>

      <div class="platForm">
        <img :src="require('@/assets/img/mobile/games.png')" alt="" class="platform-games"/>
        <div class="platForm-title" :class="{ animate__bounceIn: animate12 }">{{ filterFn('propagateText', 'title')}}</div>
        <div class="platForm-title2" :class="{ animate__bounceIn: animate12 }" v-html="filterFn('propagateText', 'text')"></div>
        <img :src="require('@/assets/img/mobile/b.png')" class="finish-animate"/>
        <img :src="require('@/assets/img/mobile/b.png')" class="finish-animate3"/>
        
        <img :src="require('@/assets/img/mobile/b.png')" class="finish-animate4"/>
        <img :src="require('@/assets/img/mobile/b.png')" class="finish-animate6"/>

        <img :src="require('@/assets/img/mobile/platforms.png')" alt="" class="platform-img"/>
        <div class="seaShell">
          <img class="seaShell-img1" :src="require('@/assets/img/mobile/rainbow-new.png')" alt="" />
          <img class="seaShell-img2" :src="require('@/assets/img/mobile/star_light-new.png')" alt=""/>
        </div>

        <div class="games" id="games">
          <div class="tit animate__animated" :class="{ animate__bounceIn: animate13 }">{{ filterFn('gamePresentationText', 'title')}}</div>
          <div class="scroll_arr">
            <vue-seamless-scroll :data="listData1" :class-option="classOption" touch="false" class="warp">
              <ul class="ul-item">
                <li v-for="(item, index) in listData1" :key="index" class="li-item">
                  <img :src="imgFn(item)" @click="toGameDetails(item.gameId)">
                </li>
              </ul>
            </vue-seamless-scroll>
            <vue-seamless-scroll :data="listData2" :class-option="classOption2" class="warp">
              <ul class="ul-item">
                <li v-for="(item, index) in listData2" :key="index" class="li-item">
                  <img :src="imgFn(item)" @click="toGameDetails(item.gameId)">
                </li>
              </ul>
            </vue-seamless-scroll>
            <vue-seamless-scroll :data="listData3" :class-option="classOption3" class="warp">
              <ul class="ul-item">
                <li v-for="(item, index) in listData3" :key="index" class="li-item">
                  <img :src="imgFn(item)" @click="toGameDetails(item.gameId)">
                </li>
              </ul>
            </vue-seamless-scroll>
            <vue-seamless-scroll :data="listData4" :class-option="classOption4" class="warp">
              <ul class="ul-item">
                <li v-for="(item, index) in listData4" :key="index" class="li-item">
                  <img :src="imgFn(item)" @click="toGameDetails(item.gameId)">
                </li>
              </ul>
            </vue-seamless-scroll>
            <vue-seamless-scroll :data="listData5" :class-option="classOption5" class="warp">
              <ul class="ul-item">
                <li v-for="(item, index) in listData5" :key="index" class="li-item">
                  <img :src="imgFn(item)" @click="toGameDetails(item.gameId)">
                </li>
              </ul>
            </vue-seamless-scroll>
          </div>
          <a class="gamesMore animate__animated" :class="{ animate__fadeInRight: animate14 }" @click="goToGames">
            <span style="text-align: center;">{{filterFn('otherText', 'more')}}</span>
          </a>
          <span class="numBox">{{ gameList.length }} {{numFn}}</span>
          
        </div>
      </div>

      <div class="aboutUs" id="aboutUs" ref="aboutUs">
        <div class="aboutUsTit animate__animated" :class="{ animate__bounceIn: animate15 }">{{ filterFn('aboutUsText', 'title') }}</div>
        <div class="aboutUsDes animate__animated" :class="{ animate__bounceIn: animate15 }" v-html="filterFn('aboutUsText', 'text')"></div>
        <div class="usMain">
          <div v-for="(item, index) in aboutData" :key="index" class="about-item animate__animated" :class="['number'+index, { animate__bounceIn: animate23 }]">
            <span style="font-family: usFont;">{{ item.num }}</span>
            <p>{{ item.text }}</p>
          </div>
        </div>
      </div>

      <div class="qwrz" id="qw" style="position: relative;">
        <div class="night">
          <div class="shooting_star"></div>
          <div class="shooting_star"></div>
          <div class="shooting_star"></div>
          <div class="shooting_star"></div>
        </div>
        <p class="animate__animated" :class="{ animate__bounceIn: animate18 }">{{ filterFn('officialCertificationText', 'title') }}</p>
        <div class="qw_mid">
          <span class="animate__animated" :class="{ animate__bounceIn: animate19 }" v-html="filterFn('officialCertificationText', 'text')"></span>
          <img class="animate__animated" :class="{ animate__fadeInRight: animate19 }" :src="officialCertificationImg('officialCertificationImg', '主图')" />
        </div>
        <div class="q4box">
          <div class="animate__animated" :class="{ animate__fadeInRight: animate20 }"><div class="q1">
            <img :src="officialCertificationImg('officialCertificationImg', '框1')"/>
          </div>{{ filterCertificationFn('officialCertification', 'text', 'tableData1') }}</div>
          <div class="animate__animated" :class="{ animate__fadeInRight: animate20 }"><div class="q2"><img :src="officialCertificationImg('officialCertificationImg', '框2')"/></div>{{ filterCertificationFn('officialCertification', 'text', 'tableData2') }}</div>
          <div class="animate__animated" :class="{ animate__fadeInRight: animate21 }"><div class="q3"><img :src="officialCertificationImg('officialCertificationImg', '框3')"/></div>{{ filterCertificationFn('officialCertification', 'text', 'tableData3') }}</div>
          <div class="animate__animated" :class="{ animate__fadeInRight: animate21 }"><div class="q4"><img :src="officialCertificationImg('officialCertificationImg', '框4')"/></div>{{ filterCertificationFn('officialCertification', 'text', 'tableData4') }}</div>
        </div>
      </div>

      <div class="about" id="about">
        <div class="tit animate__animated" :class="{ animate__bounceIn: animate16 }">{{ filterFn('partnersText', 'title') }}</div>
        <div class="des animate__animated" :class="{ animate__bounceIn: animate16 }" v-html="filterFn('partnersText', 'text')" ></div>
        <div class="light">
          <img :src="officialCertificationImg('partnersImg', '框1')" class="animate__animated" :class="{ animate__fadeInRight: animate4 }" />
          <img :src="officialCertificationImg('partnersImg', '框2')" class="animate__animated" :class="{ animate__fadeInRight: animate4 }" />
          <img :src="officialCertificationImg('partnersImg', '框3')" class="animate__animated" :class="{ animate__fadeInRight: animate5 }" />
          <img :src="officialCertificationImg('partnersImg', '框4')" class="animate__animated" :class="{ animate__fadeInRight: animate5 }" />
          <img :src="officialCertificationImg('partnersImg', '框5')" class="animate__animated" :class="{ animate__fadeInRight: animate6 }" />
          <img :src="officialCertificationImg('partnersImg', '框6')" class="animate__animated" :class="{ animate__fadeInRight: animate6 }" />
          <img :src="officialCertificationImg('partnersImg', '框7')" class="animate__animated" :class="{ animate__fadeInRight: animate7 }" />
          <img :src="officialCertificationImg('partnersImg', '框8')" class="animate__animated" :class="{ animate__fadeInRight: animate7 }" />
        </div>
      </div>
      
      <div class="vision" id="vision">
        <div class="concast">
          <div class="tit animate__animated" :class="{ animate__bounceIn: animate17 }">{{ filterFn('contactUsText', 'title') }}</div>
          <div class="des animate__animated" :class="{ animate__bounceIn: animate17 }" v-html="filterFn('contactUsText', 'text')"></div>
        </div>
        <div class="info-circle">
          <a :href="contactUsFn('contactUs', includesFn ? 'gfg126-Email' : 'Email', 'link')" target="_blank" class="animate__animated" :class="{ animate__fadeInRight: animate8 }">
            <img :src="require('@/assets/img/mobile/icon_mail.png')"/>
            <div class="items">
              <div class="label">Email</div>
              <div class="value">{{ contactUsFn('contactUs', includesFn ? 'gfg126-Email' : 'Email', 'text') }}</div>
            </div>
          </a>
          <a :href="contactUsFn('contactUs', includesFn ? 'gfg126-Skype' : 'Skype', 'link')" class="animate__animated" :class="{ animate__fadeInLeft: animate9 }">
            <img :src="require('@/assets/img/mobile/icon_skype.png')"
          />
            <div class="items">
              <div class="label">Skype</div>
              <div class="value">{{ contactUsFn('contactUs', includesFn ? 'gfg126-Skype' : 'Skype', 'text') }}</div>
            </div>
          </a>
          <a :href="contactUsFn('contactUs', includesFn ? 'gfg126-Telegram' : 'Telegram', 'link')" target="_blank" class="animate__animated" :class="{ animate__fadeInRight: animate10 }">
            <img :src="require('@/assets/img/mobile/icon_telegram.png')" />
            <div class="items">
              <div class="label">Telegram</div>
              <div class="value">{{ contactUsFn('contactUs', includesFn ? 'gfg126-Telegram' : 'Telegram', 'text') }}</div>
            </div>
          </a>
        </div>
      </div>

      <div class="dice" id="dice">
        <img :src="require('@/assets/img/mobile/cn.png')" v-if="lang === '简体中文'"/>
        <img :src="require('@/assets/img/mobile/en.png')" v-else/>
      </div>
      <div class="copyTip" v-show="copyTip">{{ $t("copy_successfully") }}</div>
      </div>
    </div>
</template>
<script>
import Header from "@/components/Header.vue";
// import vueSeamlessScroll from 'vue-seamless-scroll'
// import http from '@/utils/https.js'
import ClipboardJS from "clipboard";
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import { gameData, hotGameId } from '@/components/tool/GameConfig'
import { getList } from '@/api'

import GameItem from "@/components/GameItem.vue";

export default {
  name: "Home",
  data() {
    return {
      gameList: [],
      languageData: [],
      parseVal: [],
      maskBox: false,
      loadingShow: true,
      previewImage: '',
      boxShow: false,
      showPlayButton: true,
      animate1: false,
      animate2: false,
      animate3: false,
      animate4: false,
      animate5: false,
      animate6: false,
      animate7: false,
      animate8: false,
      animate9: false,
      animate10: false,
      animate11: false,
      animate12: false,
      animate13: false,
      animate14: false,
      animate15: false,
      animate16: false,
      animate17: false,
      animate18: false,
      animate19: false,
      animate20: false,
      animate21: false,
      animate23: false,
      // 热门游戏列表
      hotGameList: [],
      // 关于我们数据
      aboutData: [
        {
          number: 1,
          label: this.$i18n.t("mainData_p")
        },
        {
          number: 5,
          label: this.$i18n.t("mainCenter_p")
        },
        {
          number: 10,
          label: this.$i18n.t("mainLanguage_p")
        },
        {
          number: 24,
          label: this.$i18n.t("mainSafety_p")
        }
      ],
      copyTip: false,
      videoShow: false,
      lang: "",
      isShowLang: false,
      classOption: {
        step: 0.4,
        limitMoveNum: 2,
        direction: 1,
        openTouch: false,
      },
      classOption2: {
        step: 0.8,
        limitMoveNum: 2,
        direction: 1,
        openTouch: false,
      },
      classOption3: {
        step: 0.3,
        limitMoveNum: 2,
        direction: 1,
        openTouch: false,
      },
      classOption4: {
        step: 0.9,
        limitMoveNum: 2,
        direction: 1,
        openTouch: false,
      },
      classOption5: {
        step: 0.5,
        limitMoveNum: 2,
        direction: 1,
        openTouch: false,
      },
      listData1: [
        
      ],
      listData2: [
        
      ],
      listData3: [
        
      ],
      listData4: [
        
      ],
      listData5: [
       
      ],
      // 数字动画标注
      numFlag: false,
      clist: [
      {
          imgUrl: require('@/assets/img/mobile/majiang.png')
        },
        {
          imgUrl: require('@/assets/img/mobile/game01.png')
        },
        {
          imgUrl: require('@/assets/img/mobile/game02.png')
        }
      ],
      swiperOption: {
        spaceBetween: 0,
        // 衔接滑动
        loop: true,
        // 自动滑动
        autoplay: {
          delay: 2000,
          // 如果设置为true，当切换到最后一个slide时停止自动切换。
          stopOnLastSlide: false,
          // 如果设置为false，用户操作swiper之后自动切换不会停止，每次都会重新启动autoplay
          disableOnInteraction: false
        },
        // 切换效果 "coverflow"（3d流）
        effect: 'coverflow',
        // 设置slider容器能够同时显示的slides数量
        slidesPerView: 1.5,
        // 居中幻灯片。设定为true时，当前的active slide 会居中，而不是默认状态下的居左。
        centeredSlides: true,
        // 设置为true则点击slide会过渡到这个slide。
        slideToClickedSlide: true,
        coverflowEffect: {
          // slide做3d旋转时Y轴的旋转角度
          rotate: 0,
          // 每个slide之间的拉伸值，越大slide靠得越紧。5.3.6 后可使用%百分比
          stretch: 10,
          // slide的位置深度。值越大z轴距离越远，看起来越小。
          depth: 100,
          // depth和rotate和stretch的倍率，相当于depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。
          modifier: 5,
          // 是否开启slide阴影
          slideShadows: true
        },
        // 使用前进后退按钮来控制Swiper切换。
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    };
  },
  components: {
    Header,
    GameItem,
    // vueSeamlessScroll,
    Swiper,
    SwiperSlide
  },
  computed: {
    numFn() {
      if (localStorage.getItem("LANG_NAME") === '简体中文' || localStorage.getItem("LANG_NAME") === '繁体中文') {
        return '款'
      } else {
        return 'Games'
      }
    },
    includesFn() {
      console.log('jinlai', location.host.includes('gfg126'))
      return location.host.includes('gfg126')
    },
    platFormTitle() {
      console.log(localStorage.getItem("LANG_NAME"))
      let html = ''
      if (localStorage.getItem("LANG_NAME") === 'zh') {
        html = `
        <div style="padding-right: 46px;font-weight: bold;">大运捕鱼</div>
        <div style="padding-left: 67px;padding-top: 9px;font-weight: bold;">爆金来袭</div>
        `
      } else if (localStorage.getItem("LANG_NAME") === 'tw'){
        html = `
        <div style="padding-right: 46px;font-weight: bold;">大運捕魚</div>
        <div style="padding-left: 67px;padding-top: 9px;font-weight: bold;">爆金來襲</div>
        `
      } else {
        html = this.$t('fishname')
      }
      return html
    },
    mySwiper () {
      // mySwiper 是要绑定到标签中的ref属性
      return this.$refs.mySwiper.$swiper
    }
  },
  methods: {
    loadApi() {
      this.loadingShow = true
      this.boxShow = false
      window.addEventListener('scroll', this.watchScroll, true); // 绑定页面滚动事件
      document.addEventListener('touchmove', this.preventTouchMove, { passive: false });
      
      getList({ 
        typeString: 'carousel,config,language,guideText,filmText,film,popularGameText,popularGame,propagateText,gamePresentationText,gamePresentation,aboutUsText,aboutUs,officialCertificationText,officialCertification,officialCertificationImg,partnersText,partnersImg,contactUsText,contactUs,otherText',
        pageIndex:1, pageSize: 999999, type: 'xxx'
      }).then(res => {
        if (res.data.code) {
          const val = res.data.data.items
          const parseVal = val.map(ele => {
            return { ...ele, ...JSON.parse(ele.jsonString)}
          })
          this.languageData = parseVal.filter(ele => ele.type === 'language')
          
          this.languageData.forEach((ele, index) => {
            if (!localStorage.getItem('LANG_NAME') && index === 0) {
              localStorage.setItem("LANG_NAME", ele.key1);
              this.lang = ele.key1
            }
          })
          if (!(this.languageData.map(ele => ele.key1).includes(localStorage.getItem('LANG_NAME')))) {
            localStorage.setItem("LANG_NAME", this.languageData[0].key1);
            this.lang = this.languageData[0].key1
          }

          this.parseVal = parseVal
          console.log(this.lang, 'ss8d7as8d7a9s7d9as7d9as7dasd')

          // 热门游戏
          this.hotGameList = parseVal.filter(ele => ele.type === 'popularGame')
          console.log(this.hotGameList, 'this.hotGameListthis.hotGameList')
          // 游戏展示
          const gamePresentation = parseVal.filter(ele => ele.type === 'gamePresentation')
          const itemName = ['listData1', 'listData2', 'listData3', 'listData4', 'listData5']
          itemName.forEach((val, index) => {
            this[val] = gamePresentation.filter(ele => ele.key2 === (`tableData${index + 1}`)) || []
          })
          
          
          // 关于我们
          this.aboutData = []
          const tableDataVal = ['tableData1', 'tableData2', 'tableData3', 'tableData4']
          const aboutUs = parseVal.filter(ele => ele.type === 'aboutUs')
          aboutUs.forEach(ele => {
            if (tableDataVal.includes(ele.key2) && ele.name === this.lang) {
              this.aboutData[parseInt(ele.key2.match(/\d+/)[0] - 1)] = ele
            }
          })

          // 所有游戏的总数
          const gameList = parseVal.filter(ele => ele.type === 'config')
          this.gameList = gameList.filter(ele => ele.isShow)
        }
      }).finally(() => {
        this.loadingShow = false
        this.boxShow = true
        document.removeEventListener('touchmove', this.preventTouchMove, { passive: false });
      })
    },
    imgFn(val) {
      if (!val) return
      const item = val.gameItem.phoneData.find(ele => ele.name2 === '封面')
      if (this.lang === '简体中文') {
          return 'https://gfgadmin.dr5kr.live/td/api/photo/' + item.chinese
      } else {
          return 'https://gfgadmin.dr5kr.live/td/api/photo/' + item.english
      }
    },
    preventTouchMove(e) {
      e.preventDefault();
    },
    loadStars() {
      let stars = document.getElementById('stars');  // 获取stars Box
			let star = document.getElementsByClassName('star');  // 获取全部流星
			let mywidth = document.getElementById('qw').clientWidth;  // 获取当前可视宽度
      let myheight = document.getElementById('qw').clientHeight;  // 获取当前可视高度

      // 初始化流星个数 和 left,top值
			for(let i = 0;i<45;i++){
        let newStar = document.createElement('div');
        newStar.className = "star"
        newStar.style.top = randomNumber(myheight*.3, -myheight*.2) + 'px'
        newStar.style.left = randomNumber(mywidth*1.3, 0) + 'px'
        stars.appendChild(newStar)
			}

			//封装随机数函数
			function randomNumber (max, min) {
        let randomnum = Math.floor(Math.random() * (max - min + 1) + min)
        return randomnum
			}

			// 给流星添加动画延时
      for (let i = 0, len = star.length; i < len; i++)
      {
        star[i].style.animationDelay = i % 6 == 0 ? '0s' : i * 0.8 + 's';
      }
    },
    generatePreview() {
      const video = this.$refs.videoPlayer;
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Convert canvas to data URL and update previewImage
      this.previewImage = canvas.toDataURL();
    },
    enter () {
      this.mySwiper.autoplay.stop()
    },
    leave () {
      this.mySwiper.autoplay.start()
    },
    togglePlay() {
      const videoPlayer = this.$refs.videoPlayer;
      if (videoPlayer.paused || videoPlayer.ended) {
        videoPlayer.play();
        this.showPlayButton = false;
        // this.toggleFullScreen();
      } else {
        videoPlayer.pause();
        this.showPlayButton = true;
        // this.toggleFullScreen();
      }
    },
    filterFn(val, key) {
      const name = this.parseVal.filter(ele => ele.type === val)
      const item = name.find(ele => ele.key1 === this.lang)
      return item ? item[key] : ''
    },
    toggleFullScreen() {
      const videoPlayer = this.$refs.videoPlayer;
      if (!document.fullscreenElement) {
        if (videoPlayer.requestFullscreen) {
          videoPlayer.requestFullscreen();
        } else if (videoPlayer.mozRequestFullScreen) {
          videoPlayer.mozRequestFullScreen();
        } else if (videoPlayer.webkitRequestFullscreen) {
          videoPlayer.webkitRequestFullscreen();
        } else if (videoPlayer.msRequestFullscreen) {
          videoPlayer.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
    },
    // 获取热门游戏
    toGameDetails(id) {
      this.$router.push({
        path: '/details',
        query: {
          id
        }
      })
    },
    getHotGame() {
      this.hotGameList = []

      hotGameId.forEach(item => {
        gameData.forEach(game => {
          if (game.id === item) {
            this.hotGameList.push(game)
          }
        })
      });
    },
    langChange(value) {
      this.$i18n.locale = value;
      localStorage.setItem("LANG_NAME", value);
      console.log(localStorage.getItem("LANG_NAME"), "获取缓存成功");
      switch (value) {
        case "zh":
          this.lang = "中文";
          break;
        case "en":
          this.lang = "English";
          break;
        case "th":
          this.lang = "ภาษาไทย";
          break;
        /* case "id":
          this.lang = "Bahasa Indonesia";
          break; */
        case "ko":
          this.lang = "한국인";
          break;
       /*  case "ru":
          this.lang = "русский язык";
          break; */
        case "ja":
          this.lang = "日本語";
          break;
        case "vi":
          this.lang = "Tiếng Việt";
          break;
        /* case "de":
          this.lang = "Deutsch";
          break; */
        /* case "sv":
          this.lang = "Svenska";
          break; */
        case "es":
          this.lang = "Español";
          break;
        case "in":
          this.lang = "हिन्दी";
          break;
        case "pt":
          this.lang = "Português";
          break;
        case "tw":
          this.lang = "繁體中文";
          break;
      }
      // this.isShowLang = false
      this.$router.go(0);
    },
    showLang() {
      this.isShowLang = !this.isShowLang;
      setTimeout(function(){
        window.scrollTo({ top: 5800, behavior: 'smooth' })
      },100)
    },
    // isMenuFn(val) {
    //   console.log(val, 'sad')
    //   this.maskBox = val
    // },
    resetLang() {
      switch (this.$i18n.locale) {
        case "zh":
          this.lang = "中文";
          break;
        case "en":
          this.lang = "English";
          break;
        case "th":
          this.lang = "ภาษาไทย";
          break;
        case "ko":
          this.lang = "한국인";
          break;
        /* case "ru":
          this.lang = "русский язык";
          break; */
        case "ja":
          this.lang = "日本語";
          break;
        case "vi":
          this.lang = "Tiếng Việt";
          break;
        /* case "de":
          this.lang = "Deutsch";
          break; */
        /* case "sv":
          this.lang = "Svenska";
          break; */
        case "es":
          this.lang = "Español";
          break;
        /* case "it":
          this.lang = "Italiano";
          break; */
        case "in":
          this.lang = "हिन्दी";
          break;
        case "pt":
          this.lang = "Português";
          break;
        case "tw":
          this.lang = "繁體中文";
          break;
      }
    },
    videoBg() {
      this.videoShow = true
      const video = this.$refs.videoPlayer;
      video.load();
      video.oncanplaythrough = () => {
        video.play();
      };

    },
    viedoFn(val, key) {
      const name = this.parseVal.find(ele => ele.type === val)
      return name ?  name[key] : ''
    },
    isRealNum(val) {
      // isNaN()函数 把空串 空格 以及NUll 按照0来处理 所以先去除，
      if (val === "" || val == null) {
        return false;
      }
      if (!isNaN(val)) {
        //对于空数组和只有一个数值成员的数组或全是数字组成的字符串，
        //isNaN返回false，例如：'123'、[]、[2]、['123'],isNaN返回false,
        //所以如果不需要val包含这些特殊情况，则这个判断改写为if(!isNaN(val) && typeof val === 'number' )
        return true;
      } else {
        return false;
      }
    },
    filterCertificationFn(val, key, tableName) {
      const name = this.parseVal.filter(ele => ele.type === val)
      const item = name.filter(ele => ele.key1 === this.lang)
      const tableItem = item.find(ele => ele.key2 === tableName)
      return tableItem ? tableItem[key] : ''
    },
    createCopy() {
      var clipboard = new ClipboardJS(".copyAd"); //此处为点击的dom的类名
      // eslint-disable-next-line
      clipboard.on("success", (e) => {
        console.log("复制成功");
        // 释放内存
        clipboard.destroy();
        (this.copyTip = true),
          setTimeout(() => {
            this.copyTip = false;
          }, 3000);
      });
      // eslint-disable-next-line
      clipboard.on("error", (e) => {
        // 不支持复制
        console.log("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    },
    goTo() {
      this.$router.push({ path: "/allgames" });
    },
    goToGames() {
      this.$router.push({ path: "/allgames" });
    },
    officialCertificationImg(val, key) {
      const name = this.parseVal.filter(ele => ele.type === val)
      console.log(name, 'ss8ss8s8')
      const item = name.find(ele => ele.name === key)
      return item ? ('https://gfgadmin.dr5kr.live/td/api/photo/' + item['phoneSrc']) : ''
    },
    contactUsFn(val, key, contactName) {
      const name = this.parseVal.filter(ele => ele.type === val)
      const item = name.find(ele => ele.name === key)
      return item ? item[contactName] : ''
    },
    // 监听滚动条
    watchScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 获取页面滚动高度
      if (this.numFlag) {
        window.removeEventListener("scroll", this.watchScroll, true); // 移除滚动条监听
        return;
      }
      const aboutEle = this.$refs.aboutUs;
      if (aboutEle && aboutEle.offsetTop >= scrollTop && aboutEle.offsetTop <= (scrollTop + 300)) {
        for (let i = 0; i < 4; i++) {
          const ele = this.$refs['aboutNum' + i ]
          ele && ele[0].restart()	
        }
      }
      console.log(scrollTop);
      if (scrollTop < 374) {
        this.animate1 = false
        // this.animate3 = false
        this.animate4 = false
        this.animate5 = false
        this.animate6 = false
        this.animate7 = false
        this.animate8 = false
        this.animate9 = false
        this.animate10 = false
        this.animate11 = false
        this.animate12 = false
        this.animate13 = false
        this.animate14 = false
        this.animate15 = false
        this.animate16 = false
        this.animate17 = false
        this.animate18 = false
        this.animate19 = false
        this.animate20 = false
        this.animate21 = false
        // this.animate23 = false
      }

      if (scrollTop <  500) {
        this.animate1 = false;
      } 
      if (scrollTop > 500) {
        this.animate1 = true;
      } 
      if (scrollTop < 800) {
        this.animate2 = false;
      } 
      if (scrollTop > 800) {
        this.animate2 = true;
      } 
      if (scrollTop < 950) {
        // this.animate3 = false;
      } 
      if (scrollTop > 1100) {
        this.animate3 = true;
      } 

      // if (scrollTop < 4619) {
      //   this.animate4 = false;
      // } 
      // if (scrollTop < 4719) {
      //   this.animate5 = false;
      // } 
      // if (scrollTop < 4819) {
      //   this.animate6 = false;
      // } 
      // if (scrollTop < 4919) {
      //   this.animate7 = false;
      // } 

      if (scrollTop > 4619) {
        this.animate4 = true;
      } 
      if (scrollTop > 4719) {
        this.animate5 = true;
      } 
      if (scrollTop > 4819) {
        this.animate6 = true;
      } 
      if (scrollTop > 4819) {
        this.animate7 = true;
      } 

      if (scrollTop < 5432) {
        this.animate8 = false;
      } 
      if (scrollTop < 5465) {
        this.animate9 = false;
      } 
      if (scrollTop < 5570) {
        this.animate10 = false;
      } 

      if (scrollTop > 5372) {
        this.animate8 = true;
      } 
      if (scrollTop > 5465) {
        this.animate9 = true;
      } 
      if (scrollTop > 5570) {
        this.animate10 = true;
      } 
      if (scrollTop > 100) {
        this.animate11 = true;
      } 
      if (scrollTop < 1530) {
        this.animate12 = false;
      } 
      if (scrollTop > 1530) {
        this.animate12 = true;
      } 
      if (scrollTop < 2000) {
        this.animate13 = false;
      } 
      if (scrollTop > 2000) {
        this.animate13 = true;
      } 
      if (scrollTop < 2790) {
        this.animate14 = false;
      } 
      if (scrollTop > 2790) {
        this.animate14 = true;
      } 
      if (scrollTop < 3000) {
        // this.animate15 = false;
        this.animate23 = false;
      } 

      if (scrollTop > 2900) {
        this.animate15 = true;
      } 

      if (scrollTop > 3000) {
        this.animate23 = true;
      } 
      if (scrollTop < 4397) {
        this.animate16 = false;
      } 
      if (scrollTop > 4397) {
        this.animate16 = true;
      } 
      if (scrollTop < 5000) {
        this.animate17 = false;
      } 
      if (scrollTop > 5000) {
        this.animate17 = true;
      } 
      if (scrollTop < 3675) {
        this.animate18 = false;
      } 
      if (scrollTop > 3675) {
        this.animate18 = true;
      } 
      if (scrollTop < 3813) {
        this.animate19 = false;
      } 
      if (scrollTop > 3813) {
        this.animate19 = true;
      } 
      if (scrollTop < 4119) {
        this.animate20 = false;
      } 
      if (scrollTop > 4119) {
        this.animate20 = true;
      } 
      if (scrollTop < 4219) {
        this.animate21 = false;
      } 
      if (scrollTop > 4219) {
        this.animate21 = true;
      } 
      // if (scrollTop > 2800) {
      //   this.animate23 = true;
      // } 
    },
    startVideo() {
      const video = this.$refs.videoPlayer;
      video.currentTime = 7; // 设置为从第 10 秒开始播放
      // video.play(); // 自动开始播放
    }
  },
  created() {
    // this.lang = this.$i18n.locale
    // this.resetLang();
    // this.langChange(this.$i18n.locale)
    

    // this.getHotGame();

    // 不需要缓存了
    // if (sessionStorage.getItem('loadingShow') === '取消') {
    //   this.loadingShow = false
    // }
    
  },
  mounted() {
    
    this.lang = localStorage.getItem("LANG_NAME")
    this.loadApi()

    // this.loadStars()
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.watchScroll, true); // 移除滚动条监听
  },
};
</script>

<style lang="less" scoped>
.numBox {
  display: flex;
  justify-content: center;
  font-size: 30px;
  margin-top: 10px;
}
.swiper-slide {
  width: 65%; /* 缩小幻灯片的宽度 */
  margin-left: auto;
  margin-right: auto;
}
/* 隐藏 video 的全屏按钮 */
video::-webkit-media-controls-fullscreen-button {
  display: none;
}

video::-webkit-media-controls-enclosure {
  overflow: hidden;
}

video::-webkit-media-controls-panel {
  width: calc(100% + 30px); /* 调整控制面板的宽度 */
}
@langIco: {
  zh: url('~@/assets/img/zh.png') no-repeat center left;
  en: url('~@/assets/img/en.png') no-repeat center left;
  th: url('~@/assets/img/th.png') no-repeat center left;
  id: url('~@/assets/img/id.png') no-repeat center left;
  ko: url('~@/assets/img/ko.png') no-repeat center left;
  ru: url('~@/assets/img/ru.png') no-repeat center left;
  ja: url('~@/assets/img/ja.png') no-repeat center left;
  vi: url('~@/assets/img/vi.png') no-repeat center left;
  de: url('~@/assets/img/de.png') no-repeat center left;
  sv: url('~@/assets/img/sv.png') no-repeat center left;
  es: url('~@/assets/img/es.png') no-repeat center left;
  it: url('~@/assets/img/it.png') no-repeat center left;
  in: url('~@/assets/img/in.png') no-repeat center left;
  pt: url('~@/assets/img/pt.png') no-repeat center left;
  tw: url('~@/assets/img/tw.png') no-repeat center left;
};
@keyframes blink {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.8;
  }
}
@keyframes blink2 {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes shrinkage {
  0% {
    scale: 1;
  }
  50% {
    scale: 0.9;
  }
  100% {
    scale: 1;
  }
}

.home {
  width: 100vw;
  background-color: rgba(0, 0, 0, 1);
  overflow: hidden;
  padding-top: 72px;
  .best-games-container {
    // margin-top: 18.667vw;
    width: 100vw;
    height: 1110px;
    background-size: cover;
    position: relative;
    .img {
      position: absolute;
      top: 0;
      width: 100%;
    }
    .img2 {
      position: absolute;
      top: 318px;
      width: 161px;
      height: 240px;
      right: 1px;
      animation: blink 2s infinite;
    }
    .img3 {
      position: absolute;
      top: 240px;
      left: 0;
      width: 131px;
      height: 304px;
      animation: blink2 2s infinite;
    }
    .img4 {
      position: absolute;
      top: 0;
      width: 100%;
    }

    .best-games-title {
      padding-top: 99px;
      padding-bottom: 116px;
      text-align: center;
      font-size: 50px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 44px;
      text-transform:uppercase;
      font-family: "fontTitle";
      z-index: 3;
      position: relative;
    }

    .best-games-footer {
      position: absolute;
      bottom: 0;
      // left: 50%;
      // transform: translateX(-50%);
      width: 100vw;
      height: 78px;
      background: linear-gradient(to top, black 30%, transparent);
    }
    
    .best-games-btn {
      width: 64px;
      height: 111px;
      border: 2px solid #7076FF;
      border-radius: 32px;
      text-align: center;
      margin: 51px auto 0 auto;
      .image {
        display: inline-block;
        width: 13px;
        height: 60px;
        margin: 25.5px 0;
      }
    }
  }

  .video-container {
    background: #000;
    text-align: center;
    .video-title {
      padding-top: 197px;
      padding-bottom: 69px;
      text-align: center;
      font-size: 50px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 44px;
      text-transform:uppercase;
      font-family: "fontTitle";
    }

    .video-footer {
      height: 412px;
      position: relative;
      display: flex;
      align-items: center;
      // height: 80vh;
      width: 100vw;
      margin: 0 auto;
      // background: url(~@/assets/img/mobile/best-gams.png) center no-repeat;
      // background-size: cover;
      // z-index: 9999;
      
      #bg_video {
        width: 95%;
        height: 405px;
        margin: 0 auto;
        // border: 1px solid #FFFFFF;
      }
      .play-button {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20vw;
        height: 20vw;
        transform: translate(-50%, -50%);
        // font-size: 96px;
        // background: url(~@/assets/img/mobile/best-gams.png) center no-repeat;
        background-size: cover;
        // color: white;
        border: none;
        border-radius: 50%;
        padding: 20px;
        cursor: pointer;
      }
      // .custom-video {
      //   width: 100%; /* 设置视频宽度，根据需要调整 */
      //   max-width: 600px; /* 设置最大宽度，根据需要调整 */
      // }

      // #bg_video::poster {
      //   width: 50px;
      //   height: 50px;
      //   // object-fit: fill; /* 调整封面图像的填充方式 */
      // }
    }
  }

  .popular-games-container {
    background: url('~@/assets/img/mobile/hot-game.png') center no-repeat;
    background-size: contain;
    .popular-games-title {
      padding-top: 137px;
      padding-bottom: 51px;
      text-align: center;
      font-size: 50px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 44px;
      text-transform:uppercase;
      font-family: "fontTitle";
    }
    .popular-games-explain {
      width: 573px;
      font-size: 28px;
      line-height: 40px;
      font-family: MicrosoftYaHei;
      font-weight: 400;
      color: #FFFFFF;
      margin: 0 auto;
      text-align: center;
      padding-bottom: 89px;
    }

    .popular-games-swiper {
      width:100vw;
      margin: 0 auto;
      position: relative;
    }

    .popular-games-list {
      display: flex;
      justify-content: space-around;
      margin-top: 95px;
      .image-items {
        display: inline-block;
        width: 190px;
        height: 188px;
        border-radius: 20px;
        z-index: 1;
      }
    }

  }

  .platForm {
    width: 100vw;
    background-size: contain;
    background: #000;
    position: relative;
    margin-bottom: 221px;
    // background: url('~@/assets/img/mobile/games.png') 100% no-repeat;
    // background-size: 100% 100%;
    // margin-top: -1px;

    // .platForm-header {
    //   width: 100vw;
    //   height: 650px;
    //   padding-top: 201px;
    //   // background: url('~@/assets/img/mobile/games.png') 100% no-repeat;
    //   // background-size: 100% 100%;
    // }
   
    img {
      // width: 750px;
    }
    .platForm-title {
      width: 100vw;
      position: absolute;
      top: 201px;
      text-align: center;
      font-size: 50px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 44px;
      text-transform: uppercase;
      // margin: 0 auto;
      font-family: "fontTitle";
    }
    .platForm-title2 {
      width: 100vw;
      position: absolute;
      top: 302px;
      padding: 0 86px 128px 93px;
      line-height: 40px;
      font-size: 28px;
      letter-spacing: 0;
      text-align: center;
      color: #e7e7e7;
      font-family: MicrosoftYaHei;
    }
    .platform-img {
      margin-top: -751px;
      width: 750px;
      position: relative;
    }
    .span-img {
      position: relative;
      margin-top: -751px;
      display: block;
    }
    .mry-img {
      // position: absolute;
      position: absolute;
      width: 540px;
      height: 710px;
      top: 250px;
      left: 120px;
      animation: zoom 4s ease-in-out infinite; /* 无限循环的缩放动画 */
    }
    .platform-games {
      width: 750px;
    }
  }

  .games {
    position: absolute;
    bottom: 0;
    width: 100vw;
    color: #fff;
    background-size: cover;
    .tit {
      font-family: 'fontTitle';
      font-size: 50px;
      font-weight: bold;
      letter-spacing: 0;
      margin: 0 auto 159px 0;
      text-align: center;
    }
    .des {
      padding: 10px 60px;
      line-height: 40px;
      font-size: 30px;
      letter-spacing: 0;
      text-align: center;
    }
    .gamesBoxImg {
      width: 100vw;
      background: url(~@/assets/img/gamesBox.png) center no-repeat;
      background-size: contain;
      margin: 50px auto;
      &.zh {
        background: url(~@/assets/img/gamesBox_zh.png) center no-repeat;
        background-size: contain;
      }
    }
    .gamesMore {
      width: 70vw;
      height: 100px;
      margin: 159px auto 20px;
      font-size: 38px;
      font-weight: bold;
      color: #fff;
      background-image: linear-gradient(45deg, #7E429C, #501B97);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      cursor: pointer;
      flex-flow: column;
      text-transform:uppercase;
      font-family: 'fontText';
      // margin-bottom: 221px;
      .num {
        margin-top: 12px;
        font-size: 26px;
        text-transform: lowercase;
      }
    }
    .scroll_arr {
      width: 95vw;
      // height: 850px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px auto;
      padding-top: 39px;
      
      .warp {
        width: 100%;
        height: 850px;
        overflow: hidden;
        &:nth-child(2n) {
          height: 850px;
          margin-top: -150px;
        }
        ul {
          list-style: none;
          padding: 0;
          margin: 0 auto;
          &.ul-item {
            display: flex;
            flex-direction: column;
            .li-item {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 130px;
              height: 210px;
              margin: 5px;
            }
            img {
              display: block;
              width: 130px;
              height: 210px;
              border-radius: 6px;
              background: transparent;
              box-shadow: 2px 2px 15px 0 rgba(0,0,0,.18);
            }
          }
        }
      }
    }
    
  }

  .aboutUs {
    width: 100vw;
    // height: 1329px;
    background: url('~@/assets/img/mobile/aboutus_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    padding: 105px 0 261px 0;
    // padding: 105px 0 100px;
    .aboutUsTit {
      font-family: 'fontTitle';
      font-size: 50px;
      font-weight: bold;
      // letter-spacing: 0;
      margin: auto;
      padding-bottom: 51px;
      text-align: center;
      color: #fff;
    }
    .aboutUsDes {
      // padding: 10px 60px;
      padding: 0 86px 128px 93px;
      line-height: 40px;
      font-size: 28px;
      letter-spacing: 0;
      text-align: center;
      color: #e7e7e7;
      font-family: MicrosoftYaHei;
    }
    .usMain {
      // margin-top: 128px;
      width: 750px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;
      > .about-item {
        margin: 20px 0px;
        width: 292px;
        height: 302px;
        background: url('~@/assets/img/aboutus_frame.png') center no-repeat;
        background-size: contain;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 15px 28px;
        p {
          width: 82%;
          font-size: 30px;
          color: #fff;
          text-align: center;
          line-height: 40px;
        }
        span {
          font-size: 80px;
          font-weight: bold;
          color: rgb(171, 114, 248);
        }
      }
    }
  }

  .qwrz {
    position: relative;
    z-index:1;
    width: 100%;
    // height: 1600px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    background: #fafbfd;
    padding-top: 100px;
    background: url('~@/assets/img/mobile/qwrz.png');
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -255px;
    > p {
      font-family: 'fontTitle';
      font-size: 50px;
      color: #fff;
      letter-spacing: -1px;
      margin-bottom: 60px;
      font-weight: bold;
      padding-top: 157px;
    }
    .qw_mid {
      width: 80%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 60px;
      span {
        width: 50%;
        color: #fff;
        padding: 20px;
        line-height: 1.2;
        font-size: 24px;
      }
      img {
        width: 50%;
      }
    }
    .q4box {
      width: 80%;
      display: flex;
      flex-flow: wrap;
      justify-content: space-between;
      align-items: center;
      font-size: 24px;
      margin-bottom: 291px;
      z-index: 1; /* 将上方 div 置于上层 */
      // background: url('~@/assets/img/mobile/wizar2.gif)');
      > div {
        width: 48%;
        height: 282px;
        // font-size: 20px;
        padding: 38px 25px 41px 39px;
        margin-bottom: 30px;
        line-height: 1.1;
        text-overflow: ellipsis;
        overflow: hidden;
        background: #2D114C;
        opacity: 0.8;
        border-radius: 40px;
        // margin-left: 24px;
        word-break: break-all;
        img {
          display: inline-block;
          width: 100%;
          height: 100%;
          // width: 199px;
          // height: 39px;
         
          // background: url('~@/assets/img/mobile/q1.png') no-repeat center;
          // background-size: contain;
        }
        .q1 {
          margin: 0 auto 35px auto;
          width: 199px;
          height: 39px;
        }
        .q2 {
          margin: 0 auto 35px auto;
          width: 180px;
          height: 29px;
        }
        .q3 {
          margin: 0 auto 35px auto;
          width: 141px;
          height: 37px
        }
        .q4 {
          margin: 0 auto 35px auto;
          width: 199px;
          height: 40px;
        }
      }
    }
  }

  .about {
    position: relative;
    z-index:0;
    font-family: 'fontTitle';
    width: 100vw;
    background: #fff;
    color: #fff;
    padding: 160px 0;
    background: url('~@/assets/img/mobile/hzhb.png') center no-repeat;
    background-size: cover;
    margin-top: -203px;
    z-index: 0;
    padding-bottom: 233px;
    .tit {
      margin: 60px auto 60px;
      text-align: center;
      font-size: 50px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 44px;
      text-transform:uppercase;
      font-family: "fontTitle";
    }
    .des {
      // padding: 10px 60px;
      padding: 0 77px 0 103px;
      line-height: 40px;
      font-size: 28px;
      letter-spacing: 0;
      text-align: center;
      // margin-bottom: 60px;
      color: #fff;
      font-family: MicrosoftYaHei;
    }
    .light {
      display: flex;
      width: 100vw;
      height: auto;
      justify-content: center;
      flex-flow: wrap;
      padding: 0 85px;
      
      img {
        width: 277px;
        height: 100px;
        margin-top: 66px;
        background: rgba(247,247,247,0);
        border-radius: 7px;
        &:nth-child(2n) {
          margin-left: 25px;
        }
        // margin-right: 25px;
      }
    }
  }

  .vision {
    width: 100vw;
    // height: 1300px;
    // background: url('~@/assets/img/contactbg.jpeg');
    // background-repeat: no-repeat;
    // background-size: cover;
    // color: #fff;
    // padding: 298px 0 480px 0;
    .concast {
      background: linear-gradient(-90deg, #7E429C, #501B97);
      padding-top: 61px;
      padding-bottom: 39px; 
      color: #fff;
      .tit {
        font-family: 'fontTitle';
        font-size: 50px;
        font-weight: bold;
        letter-spacing: 0;
        margin: 0 auto 60px;
        text-align: center;
      }
      .des {
        padding: 10px 60px;
        line-height: 40px;
        font-size: 28px;
        letter-spacing: 0;
        text-align: center;
        margin-bottom: 20px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
      }
    }

    #line_video {
      width: 100vw;
    }
  }
  
  .end {
    width: 100vw;
    // min-height: 100vh;
    background: #fff;
    color: #000;
    padding: 60px 40px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    img {
      width: 240px;
      // margin-bottom: 60px;
    }
    .txt {
      width: 100%;
      margin: 0 0 40px;
      opacity: 0.3;
      font-size: 24px;
      color: #000;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0;
    }
    .svg img {
      margin-right: 30px;
    }
    .flex-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .lang {
      position: relative;
      font-size: 24px;
      color: #000;
      letter-spacing: 0;
      line-height: 28px;
      padding: 0 12px 0 0;
      span::after {
        background: url('~@/assets/img/arrow.png') center no-repeat;
        background-size: contain;
        content: "";
        width: 40px;
        height: 28px;
      }
      each(@langIco, {
        .@{key} {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .@{key}::before {
          content: '';
          display: block;
          width: 40px;
          height: 26px;
          margin-right: 10px;
          background: @value;
          background-size: contain;
        }
      });
    }
  }
  .lang-change {
    display: flex;
    justify-content: flex-start;
    flex-flow: wrap;
    width: 100%;
    padding: 80px;
    background: #fafafa;
    transition: all 0.5s ease;
    li {
      width: 50%;
      height: 120px;
      color: #000;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 20px;
      filter: grayscale(95%);
      opacity: 0.5;
      &.active {
        filter: grayscale(0);
        opacity: 1;
      }
      each(@langIco, {
        // &.@{key} {
        //   display: flex;
        //   justify-content: center;
        //   align-items: center;
        // }
        &.@{key}::before {
          content: '';
          display: block;
          width: 52px;
          height: 36px;
          margin-right: 14px;
          background: @value;
          background-size: contain;
        }
      });
    }
  }
  .info-circle {
    width: 100vw;
    padding: 30px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    background: url('~@/assets/img/mobile/contactbg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    color: #fff;
    padding: 298px 0 480px 0;
    > a img {
      width: 550px;
      height: 168px;
      margin-bottom: 60px;
      position: relative;
    }
    .items {
      position: absolute;
      top: 0;
      left: 203px;
      height: 94px;
      padding: 45px 0;
      color: #fff;
      .label {
        font-weight: bold;
        font-size: 30px;
      }
      .value {
        padding-top: 16px;
        font-size: 24px;
        font-weight: 400;
      }
    }
  }
}
.animate__animated {
  opacity: 0;
}
.number0 {
  animation-delay: 0;
}
.number1 {
  animation-delay: 300ms;
}
.number2 {
  animation-delay: 600ms;
}
.number3 {
  animation-delay: 900ms;
}

.dice {
  width: 100vw;
  min-height: 100px;
  background: url('~@/assets/img/mobile/bg 2.png') center no-repeat;
  background-size: cover;
  position: relative;
  margin-top: -255px;
  img {
    width: 100vw;
  }
}


.breathe-div {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-name: breathe;
    -webkit-animation-duration: 1500ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
}

@-webkit-keyframes breathe {
    0% {
        opacity: .4;
    }

    100% {
        opacity: 1;
    }
}
.swiper-star2 {
  position: absolute;
  top: 75px;
  left: -20px;
  width: 150px;
  height: 150px;
  animation-delay: 1s;
  -webkit-animation-name: breathe3;
}
.swiper-star4 {
  position: absolute;
  width: 50px;
  height: 50px;
  top: -80px;
  right: 170px;
  animation-duration: 4s;
  animation-delay: 2s;
}
.swiper-star5 {
  position: absolute;
  top: -50px;
  right: 120px;
  width: 50px;
  height: 50px;
  animation-duration: 4s;
  animation-delay: 1s;
}
.swiper-star6 {
  position: absolute;
  right: 20px;
  animation-delay: 2s;
  -webkit-animation-name: breathe2;
}

@-webkit-keyframes breathe2 {
    0% {
        opacity: .2;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes breathe3 {
    0% {
        opacity: .2;
    }

    100% {
        opacity: 0.7;
    }
}


.swiper-star7 {
  position: absolute;
  right: 45px;
  right: 50px;
}

.finish-animate {
  position: absolute;
  top: 500px;
  left: 50px;
  width: 100px;
  // height: 80px;
  // animation: decorateUp2 10s infinite ease-in-out;
  animation: animateBubble 8s linear infinite, sideWays 2s ease-in-out infinite alternate;
  animation-delay: 1s;
  // animation: jumpBoxHandler 3.8s infinite;
}

.finish-animate3 {
  position: absolute;
  top: 500px;
  left: 300px;
  width: 80px;
  // height: 80px;
  // animation: cloud_shadow 2s ease-in-out infinite;
  // animation: decorateUp 4s ease-in-out infinite;
  // animation: decorateUp2 5s infinite ease-in-out;
  // animation-fill-mode: forwards;
  animation: animateBubble 9s linear infinite, sideWays 2s ease-in-out infinite alternate;
  animation-delay: 2s;
  // animation: jumpBoxHandler 3.8s infinite;
}
.finish-animate6 {
  position: absolute;
  top: 500px;
  left: 550px;
  width: 80px;
  // height: 80px;
  // animation: cloud_shadow 2s ease-in-out infinite;
  animation: animateBubble 10s linear infinite, sideWays 2s ease-in-out infinite alternate;
  animation-delay: 1s;
  // animation: jumpBoxHandler 3.8s infinite;
}
@keyframes animateBubble {
  0% {
    opacity: 1;
    transform: translateX(0) translateY(0);
  }
  // 20% {
  //   opacity: 0.9;
  //   transform: translateX(20px) translateY(-30px) ;
  // }
  // 50% {
  //   opacity: 0.8;
  //   transform: translateX(0) translateY(-50px);
  // }
  100% {
    opacity: 0;
    transform: translateX(0) translateY(-140px) ;
  }
}

@keyframes sideWays {
  0% {
    margin-left: 0px;
  }

  100% {
    margin-left: 50px;
  }
}
.finish-animate4 {
  position: absolute;
  top: 500px;
  left: 400px;
  width: 90px;
  animation: animateBubble 7s linear infinite, sideWays 2s ease-in-out infinite alternate;
  z-index:98;
}

.qw-animate {
  position: absolute;
  top: 10px;
  left: 280px;
  width: 150px;
  // height: 80px;
  animation: cloud_shadow 2s ease-in-out infinite;
}
@keyframes cloud {
  50% {
    transform: translateY(-20px);
  }
}
@keyframes decorateUp {
  0% {
    transform: translate(-40px, 50px);
  }
  50% {
    transform: translate(40, -50px); /* 可配置跳动方向 */
  }
  70% {
    transform: translate(-40, -50px); /* 可配置跳动方向 */
  }
  100% {
    transform: translate(70px, -100px);
  }
}


@keyframes decorateUp2 {
  0% {
    opacity: 1;
    transform: translateX(0) translateY(0);
  }
  20% {
    opacity: 0.9;
    transform: translateX(80px) translateY(-30px) cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    opacity: 0.8;
    transform: translateX(0) translateY(-80px) cubic-bezier(0.42, 0, 0.58, 1);
  }
  70% {
    opacity: 0.5;
    transform: translateX(80px) translateY(-120px) cubic-bezier(0.42, 0, 0.58, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(80px) translateY(-150px) ;
  }
}

// cubic-bezier(0.42, 0, 0.58, 1)
@keyframes decorateUp3 {
  0% {
    opacity: 1;
    transform: translateX(0) translateY(0);
  }
  20% {
    opacity: 0.9;
    transform: translateX(-70px) translateY(-30px) cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    opacity: 0.8;
    transform: translateX(0) translateY(-50px) cubic-bezier(0.42, 0, 0.58, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(-70px) translateY(-150px) ;
  }
}

// @keyframes decorateUp3 {
//   0% {
//     opacity: 1;
//     transform: matrix(1, 0, 0, 1, 0, 0);
//   }
//   50% {
//     opacity: 1;
//     transform: matrix(1, 0, 0.5, 1, 50, -250);
//   }
//   100% {
//     opacity: 0;
//     transform: matrix(1, 0, 1, 1, 100, -500);
//   }
// }


.circle {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  width: 100px;
  height: 100px;
  background: red;
  border-radius: 50%;
  animation: circle 5s infinite linear;
}

@keyframes circle {
  0% {
    transform: translateX(0) translateY(-50px);
  }
  25% {
    transform: translateX(50px) translateY(0);
  }
  50% {
    transform: translateX(0) translateY(50px);
  }
  75% {
    transform: translateX(-50px) translateY(0);
  }
  100% {
    transform: translateX(0) translateY(-50px);
  }
}
@keyframes decorateUprt {
    0%{
        left: 8px;
        bottom: -5px;
        // background-color: rgb(255, 255, 255,0.5);
    }
    100% {
        left: 0px;
        bottom: 234px;
        // background-color: rgb(255, 255, 250, 0);
    }
}
@keyframes cloud_shadow {
  50% {
    transform: translateY(0px) scale(.7);
    opacity: .08;
  }
}
@keyframes jumpBoxHandler { /* css事件 */
  0% {
    transform: translate(0px, 10px);
  }
  50% {
    transform: translate(0, 50px); /* 可配置跳动方向 */
  }
  100% {
    transform: translate(50px, 0px);
  }
}
@keyframes zoom {
  0% { transform: scale(1); } /* 初始状态为原大小 */
  50% { transform: scale(1.2); } /* 缩放到 1.5 倍大小 */
  100% { transform: scale(1); } /* 恢复到原大小 */
}



/* 流星特效 */
.night {
  position: relative;
  width: 100%;
  height: 100%;
  transform: rotateZ(145deg);
  position: absolute;
  top: -500px;
}

.shooting_star {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 2px;
  background: linear-gradient(-45deg, #5f91ff, rgba(0, 0, 255, 0));
  border-radius: 999px;
  filter: drop-shadow(0 0 6px #699bff);
  -webkit-animation: tail 3000ms ease-in-out infinite, shooting 3000ms ease-in-out infinite;
          animation: tail 3000ms ease-in-out infinite, shooting 3000ms ease-in-out infinite;
}
.shooting_star::before {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  right: 0;
  height: 2px;
  background: linear-gradient(-45deg, rgba(0, 0, 255, 0), #5f91ff, rgba(0, 0, 255, 0));
  transform: translateX(50%) rotateZ(45deg);
  border-radius: 100%;
  -webkit-animation: shining 3000ms ease-in-out infinite;
          animation: shining 3000ms ease-in-out infinite;
}
.shooting_star::after {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  right: 0;
  height: 2px;
  background: linear-gradient(-45deg, rgba(0, 0, 255, 0), #5f91ff, rgba(0, 0, 255, 0));
  transform: translateX(50%) rotateZ(45deg);
  border-radius: 100%;
  -webkit-animation: shining 3000ms ease-in-out infinite;
          animation: shining 3000ms ease-in-out infinite;
  transform: translateX(50%) rotateZ(-45deg);
}
.shooting_star:nth-child(1) {
  top: calc(50% - 88px);
  left: calc(50% - 24px);
  -webkit-animation-delay: 9532ms;
          animation-delay: 9532ms;
}
.shooting_star:nth-child(1)::before, .shooting_star:nth-child(1)::after {
  -webkit-animation-delay: 9532ms;
          animation-delay: 9532ms;
}
.shooting_star:nth-child(2) {
  top: calc(50% - 169px);
  left: calc(50% - 83px);
  -webkit-animation-delay: 6125ms;
          animation-delay: 6125ms;
}
.shooting_star:nth-child(2)::before, .shooting_star:nth-child(2)::after {
  -webkit-animation-delay: 6125ms;
          animation-delay: 6125ms;
}
.shooting_star:nth-child(3) {
  top: calc(50% - -30px);
  left: calc(50% - 117px);
  -webkit-animation-delay: 6014ms;
          animation-delay: 6014ms;
}
.shooting_star:nth-child(3)::before, .shooting_star:nth-child(3)::after {
  -webkit-animation-delay: 6014ms;
          animation-delay: 6014ms;
}
.shooting_star:nth-child(4) {
  top: calc(50% - 129px);
  left: calc(50% - 31px);
  -webkit-animation-delay: 1699ms;
          animation-delay: 1699ms;
}
.shooting_star:nth-child(4)::before, .shooting_star:nth-child(4)::after {
  -webkit-animation-delay: 1699ms;
          animation-delay: 1699ms;
}
.shooting_star:nth-child(5) {
  top: calc(50% - -2px);
  left: calc(50% - 219px);
  -webkit-animation-delay: 4576ms;
          animation-delay: 4576ms;
}
.shooting_star:nth-child(5)::before, .shooting_star:nth-child(5)::after {
  -webkit-animation-delay: 4576ms;
          animation-delay: 4576ms;
}
.shooting_star:nth-child(6) {
  top: calc(50% - -69px);
  left: calc(50% - 67px);
  -webkit-animation-delay: 5428ms;
          animation-delay: 5428ms;
}
.shooting_star:nth-child(6)::before, .shooting_star:nth-child(6)::after {
  -webkit-animation-delay: 5428ms;
          animation-delay: 5428ms;
}
.shooting_star:nth-child(7) {
  top: calc(50% - 107px);
  left: calc(50% - 46px);
  -webkit-animation-delay: 1842ms;
          animation-delay: 1842ms;
}
.shooting_star:nth-child(7)::before, .shooting_star:nth-child(7)::after {
  -webkit-animation-delay: 1842ms;
          animation-delay: 1842ms;
}
.shooting_star:nth-child(8) {
  top: calc(50% - 158px);
  left: calc(50% - 122px);
  -webkit-animation-delay: 9452ms;
          animation-delay: 9452ms;
}
.shooting_star:nth-child(8)::before, .shooting_star:nth-child(8)::after {
  -webkit-animation-delay: 9452ms;
          animation-delay: 9452ms;
}
.shooting_star:nth-child(9) {
  top: calc(50% - 121px);
  left: calc(50% - 141px);
  -webkit-animation-delay: 3937ms;
          animation-delay: 3937ms;
}
.shooting_star:nth-child(9)::before, .shooting_star:nth-child(9)::after {
  -webkit-animation-delay: 3937ms;
          animation-delay: 3937ms;
}
.shooting_star:nth-child(10) {
  top: calc(50% - -35px);
  left: calc(50% - 77px);
  -webkit-animation-delay: 9038ms;
          animation-delay: 9038ms;
}
.shooting_star:nth-child(10)::before, .shooting_star:nth-child(10)::after {
  -webkit-animation-delay: 9038ms;
          animation-delay: 9038ms;
}
.shooting_star:nth-child(11) {
  top: calc(50% - 144px);
  left: calc(50% - 285px);
  -webkit-animation-delay: 7676ms;
          animation-delay: 7676ms;
}
.shooting_star:nth-child(11)::before, .shooting_star:nth-child(11)::after {
  -webkit-animation-delay: 7676ms;
          animation-delay: 7676ms;
}
.shooting_star:nth-child(12) {
  top: calc(50% - 196px);
  left: calc(50% - 8px);
  -webkit-animation-delay: 6366ms;
          animation-delay: 6366ms;
}
.shooting_star:nth-child(12)::before, .shooting_star:nth-child(12)::after {
  -webkit-animation-delay: 6366ms;
          animation-delay: 6366ms;
}
.shooting_star:nth-child(13) {
  top: calc(50% - 60px);
  left: calc(50% - 108px);
  -webkit-animation-delay: 1498ms;
          animation-delay: 1498ms;
}
.shooting_star:nth-child(13)::before, .shooting_star:nth-child(13)::after {
  -webkit-animation-delay: 1498ms;
          animation-delay: 1498ms;
}
.shooting_star:nth-child(14) {
  top: calc(50% - -95px);
  left: calc(50% - 85px);
  -webkit-animation-delay: 9961ms;
          animation-delay: 9961ms;
}
.shooting_star:nth-child(14)::before, .shooting_star:nth-child(14)::after {
  -webkit-animation-delay: 9961ms;
          animation-delay: 9961ms;
}
.shooting_star:nth-child(15) {
  top: calc(50% - 154px);
  left: calc(50% - 11px);
  -webkit-animation-delay: 7685ms;
          animation-delay: 7685ms;
}
.shooting_star:nth-child(15)::before, .shooting_star:nth-child(15)::after {
  -webkit-animation-delay: 7685ms;
          animation-delay: 7685ms;
}
.shooting_star:nth-child(16) {
  top: calc(50% - -79px);
  left: calc(50% - 213px);
  -webkit-animation-delay: 2812ms;
          animation-delay: 2812ms;
}
.shooting_star:nth-child(16)::before, .shooting_star:nth-child(16)::after {
  -webkit-animation-delay: 2812ms;
          animation-delay: 2812ms;
}
.shooting_star:nth-child(17) {
  top: calc(50% - -60px);
  left: calc(50% - 123px);
  -webkit-animation-delay: 6698ms;
          animation-delay: 6698ms;
}
.shooting_star:nth-child(17)::before, .shooting_star:nth-child(17)::after {
  -webkit-animation-delay: 6698ms;
          animation-delay: 6698ms;
}
.shooting_star:nth-child(18) {
  top: calc(50% - -174px);
  left: calc(50% - 15px);
  -webkit-animation-delay: 4332ms;
          animation-delay: 4332ms;
}
.shooting_star:nth-child(18)::before, .shooting_star:nth-child(18)::after {
  -webkit-animation-delay: 4332ms;
          animation-delay: 4332ms;
}
.shooting_star:nth-child(19) {
  top: calc(50% - 87px);
  left: calc(50% - 91px);
  -webkit-animation-delay: 2407ms;
          animation-delay: 2407ms;
}
.shooting_star:nth-child(19)::before, .shooting_star:nth-child(19)::after {
  -webkit-animation-delay: 2407ms;
          animation-delay: 2407ms;
}
.shooting_star:nth-child(20) {
  top: calc(50% - 125px);
  left: calc(50% - 287px);
  -webkit-animation-delay: 5693ms;
          animation-delay: 5693ms;
}
.shooting_star:nth-child(20)::before, .shooting_star:nth-child(20)::after {
  -webkit-animation-delay: 5693ms;
          animation-delay: 5693ms;
}

@-webkit-keyframes tail {
  0% {
    width: 0;
  }
  30% {
    width: 100px;
  }
  100% {
    width: 0;
  }
}

@keyframes tail {
  0% {
    width: 0;
  }
  30% {
    width: 100px;
  }
  100% {
    width: 0;
  }
}
@-webkit-keyframes shining {
  0% {
    width: 0;
  }
  50% {
    width: 30px;
  }
  100% {
    width: 0;
  }
}
@keyframes shining {
  0% {
    width: 0;
  }
  50% {
    width: 30px;
  }
  100% {
    width: 0;
  }
}
@-webkit-keyframes shooting {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(300px);
  }
}
@keyframes shooting {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(300px);
  }
}
@-webkit-keyframes sky {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(405deg);
  }
}
@keyframes sky {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(405deg);
  }
}
.mask-box {
  position: fixed;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  top: 0;
  left: 0;
  z-index: 105;
}
.video-bg {
  position: absolute;
  width: 100vw;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: 3;
  background: lab(0 0 0 / 0.2);
  img {
    position: absolute;
    top: 100px;
  }
}

.loading-box {
  position: fixed;
  background: #000;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  justify-content: center;
  display: flex;
  align-items: center;
}

.loading {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;
  column-gap: 5px;
  width: 20px;
}

.loading div {
  width: 7px;
  height: 30px;
  background-color: #6e399a;
  animation: wq_scaleY 1.2s ease-in-out infinite;
}

.loading div:nth-child(2) {
  animation-delay: 0.1s;
}

.loading div:nth-child(3) {
  animation-delay: 0.2s;
}

.loading div:nth-child(4) {
  animation-delay: 0.3s;
}

.loading div:nth-child(5) {
  animation-delay: 0.4s;
}

.seaShell {
  position: absolute;
  top: 1270px;
  width: 278px;
  left: 20px;
  height: 267px;
  .seaShell-img1 {
    position: absolute;
    left: 0;
    top: 0;
    width: 278px;
    height: 267px;
    animation: shrinkage 1s infinite;
  }
  .seaShell-img2 {
    position: absolute;
    top: 40px;
    left: 35px;
    width: 156px;
    height: 146px;
    animation: blink 1s infinite;
  }
}

@keyframes wq_scaleY {
  0%,
  40%,
  100% {
    transform: scaleY(1);
  }

  20% {
    transform: scaleY(2.5);
  }
}

</style>
